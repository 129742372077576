<template>
  <div class="comp-main">
    <el-row>
      <el-col :span="24">
        <h3><span class="introduce-character-text-title">平台兼容性</span></h3>
      </el-col>
    </el-row>

    <el-row>
      <el-col :span="24">
        <span class="introduce-character-text">
          如果遇到无法运行的未知错误，是否可以考虑是版本兼容性问题？当前其他版本正在紧急开发中，我们将尽快进行产品兼容性优化。
      </span>
      </el-col>
    </el-row>

    <el-row style="margin-top: 20px;">
      <el-col :span="5">
        <table class="compatibility-table">
          <tr>
            <th class="introduce-character-text">Vue2</th>
            <th class="introduce-character-text">Vue3</th>
          </tr>
          <tr>
            <td class="introduce-character-text">✅</td>
            <td class="introduce-character-text">✅</td>
          </tr>
        </table>
      </el-col>
    </el-row>

    <el-row style="margin-top: 20px;">
      <el-col :span="24">
        <table class="compatibility-table">
          <tr>
            <th class="introduce-character-text">App</th>
            <th class="introduce-character-text">快应用</th>
            <th class="introduce-character-text">微信小程序</th>
            <th class="introduce-character-text">支付宝小程序</th>
            <th class="introduce-character-text">百度小程序</th>
            <th class="introduce-character-text">字节小程序</th>
            <th class="introduce-character-text">QQ小程序</th>
          </tr>
          <tr>
            <td class="introduce-character-text">❌</td>
            <td class="introduce-character-text">❌</td>
            <td class="introduce-character-text">❌</td>
            <td class="introduce-character-text">❌</td>
            <td class="introduce-character-text">❌</td>
            <td class="introduce-character-text">❌</td>
            <td class="introduce-character-text">❌</td>
          </tr>
        </table>
      </el-col>
    </el-row>

    <el-row style="margin-top: 20px;">
      <el-col :span="17">
        <table class="compatibility-table">
          <tr>
            <th class="introduce-character-text">钉钉小程序</th>
            <th class="introduce-character-text">快手小程序</th>
            <th class="introduce-character-text">快手小程序</th>
            <th class="introduce-character-text">京东小程序</th>
          </tr>
          <tr>
            <td class="introduce-character-text">❌</td>
            <td class="introduce-character-text">❌</td>
            <td class="introduce-character-text">❌</td>
            <td class="introduce-character-text">❌</td>
          </tr>
        </table>
      </el-col>
    </el-row>

    <el-row style="margin-top: 20px;">
      <el-col :span="24">
        <table class="compatibility-table">
          <tr>
            <th class="introduce-character-text">H5-Safari</th>
            <th class="introduce-character-text">Android Browser</th>
            <th class="introduce-character-text">微信浏览器(Android)</th>
            <th class="introduce-character-text">QQ浏览器(Android)</th>
            <th class="introduce-character-text">Chrome</th>
            <th class="introduce-character-text">IE</th>
            <th class="introduce-character-text">Edge</th>
            <th class="introduce-character-text">Firefox</th>
            <th class="introduce-character-text">PC-Safari</th>
          </tr>
          <tr>
            <td class="introduce-character-text">✅</td>
            <td class="introduce-character-text">✅</td>
            <td class="introduce-character-text">✅</td>
            <td class="introduce-character-text">✅</td>
            <td class="introduce-character-text">✅</td>
            <td class="introduce-character-text">✅</td>
            <td class="introduce-character-text">✅</td>
            <td class="introduce-character-text">✅</td>
            <td class="introduce-character-text">✅</td>
          </tr>
        </table>
      </el-col>
    </el-row>

  </div>
</template>

<script>
export default {
  name: "index"
}
</script>

<style>
.compatibility-table {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid var(--current-page--sidebar-background-color); /* 外边框细线 */
}

.compatibility-table th, .compatibility-table td {
  padding: 8px;
  text-align: center;
  border-bottom: 1px solid var(--current-page--sidebar-background-color); /* 单元格底部细线 */
}

/* 动态添加中间的竖线 */
.compatibility-table th:not(:first-child), .compatibility-table td:not(:first-child) {
  border-left: 1px solid var(--current-page--sidebar-background-color); /* 在每列的左边添加竖直线，跳过第一列 */
}

.compatibility-table th {
  background-color: var(--current-page-line-color);
}
</style>

const { isMobile } = require("../util/isMobile");

const isMobileDevice = isMobile();

module.exports = {
    list: [
        {
            parentName: isMobileDevice ? "指南" : "开发指南",
            child: [
                {
                    name: "介绍",
                    path: "/",
                    selected: true
                },
                {
                    name: "安装",
                    path: "/install",
                    selected: true
                },
                {
                    name: isMobileDevice ? "上手" : "快速上手",
                    path: "/left-hand",
                    selected: false
                },
                {
                    name: isMobileDevice ? "参数" : "参数集合",
                    path: "/parameter-set",
                    selected: false
                },
                {
                    name: isMobileDevice ? "函数" : "函数入口",
                    path: "/func",
                    selected: false
                },
                {
                    name: isMobileDevice ? "版本" : "历史版本",
                    path: "/version",
                    selected: false
                },
                {
                    name: isMobileDevice ? '兼容' : '关于兼容',
                    path: '/compatibility',
                    selected: false
                },
            ]
        },
        {
            parentName: "使用",
            child: [
                {
                    name: isMobileDevice ? "登录" : "人脸登录",
                    path: "/face-login",
                    selected: false
                },
                {
                    name: isMobileDevice ? "打卡" : "人脸打卡",
                    path: "/face-punch",
                    selected: false
                },
                {
                    name: isMobileDevice ? "睡眠" : "睡眠检测",
                    path: "/sleep-detection",
                    selected: false
                },
                {
                    name: isMobileDevice ? "添加" : "人脸添加",
                    path: "/face-add",
                    selected: false
                },
                {
                    name: isMobileDevice ? "实战" : "项目实战",
                    path: '/projectPractice',
                    selected: false
                },
                {
                    name: isMobileDevice ? "DIY" : "自定义样式",
                    path: '/customStyle',
                    selected: false
                },
                {
                    name: isMobileDevice ? '重写' : '重写函数',
                    path: '/rewrite',
                    selected: false
                }
            ]
        }
    ]
};



let defaultCode = `this.$faceEffet.init({
     el: 'dangqface',
     callBack: (data) => {
       console.log(data);
     }
   })`

let rewriteCode = `this.$faceEffet.init({
     el: 'dangqface',
      /**
      * @param appData 当前框架内置对象所有属性值
      * @param results 人脸关键点位
      * @param currentObj 当前对象
      * @param callBackResult 回调函数
      * @param stopRecording 停止录制
      * @param startRecording 重新开始录制，可以不用调用，可以直接调用effet.restart方法
      */
     action:(appData,results,currentObj,callBackResult,stopRecording,startRecording)=>{
                 const landmarks = results.multiFaceLandmarks[0]; // 拿到第0张人脸，可循环
                 // 获取面部关键点
                 const upperLipBottom = landmarks[13];
                 const lowerLipTop = landmarks[14];
                 const leftEyeTop = landmarks[159];
                 const leftEyeBottom = landmarks[145];
                 const rightEyeTop = landmarks[386];
                 const rightEyeBottom = landmarks[374];
                 const noseTip = landmarks[1]; // 鼻尖的标记点
                
                 // 在这里可以写你自己的算法，定义新的动作
                 // 可以参考人脸登录的算法：https://gitee.com/susantyp/effet/blob/master/src/core/action/checkLogin/index.js
                
                 // 这样按需添加你自己的动作
         }
     })
     callBack: (data) => {
       console.log(data);
     }
   })`

export default {
    defaultCode,
    rewriteCode
}

<template>
  <div class="wrapper">
    <div class="content" v-show="imgLoadState">
      <section class="section hero">
        <brief/>
      </section>
      <section class="section gradient-purple" >
        <doc />
      </section>
    </div>
    <div class="image-container">
      <img class="bg-mian"
           src="~@/assets/bg.webp"
           alt="image"
           @load="onImageLoad"
      />
      <div class="image-welcome" v-show="imgLoadState">
        <div class="container_mouse">

              <span class="mouse-btn" v-if="scrollText === '往下滑动'" @click="scrollToBottom()">
                  <span class="mouse-scroll"></span>
              </span>

              <div style="width: 30px;height: 60px" v-else></div>

          <span v-if="scrollText === '往下滑动'" style="cursor: pointer;" @click="scrollToBottom()">
      欢迎使用Effet.js &nbsp; {{scrollText}}
          </span>
          <span v-else style="cursor: pointer;" @click="scrollToBottom()">

            <div class="card-effet-app" style="margin-bottom: -66px">
              <div class="loader-effet-app">
                <div class="words-effet-app">
                  <span class="word-effet-app">轻量高效</span>
                  <span class="word-effet-app">动画生动</span>
                  <span class="word-effet-app">简单易用</span>
                  <span class="word-effet-app">人脸交互</span>
                  <span class="word-effet-app">增强体验</span>
                  <span class="word-effet-app">灵活集成</span>
                  <span class="word-effet-app">开箱即用</span>
                </div>
              </div>
            </div>&nbsp;&nbsp;
            <div style="width: 100px;margin-left: 100px;">{{scrollText}}</div>

          </span>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import doc from '@/views/doc'
import brief from "@/views/components/brief";
export default {
  components:{
    doc,
    brief
  },
  data(){
    return {
      previousScale: 1,
      imgLoadState:false,
      scrollText:'往下滑动'
    }
  },
  name: "HomePage",
  mounted() {
    this.redirectToHttps();
    window.scrollTo(0, 0);
    window.addEventListener('beforeunload', this.handleBeforeUnload);
    this.initGsapAnimation();
  },
  beforeDestroy() {
    // 组件销毁前，移除事件监听器
    window.removeEventListener('beforeunload', this.handleBeforeUnload);
  },
  methods: {
    redirectToHttps() {
      const isLocal = window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1';
      if (!isLocal && window.location.protocol === 'http:') {
        window.location.href = 'https://faceeffet.com/';
      }
    },
    scrollToBottom() {
      // 修改后的滚动逻辑，滚动到大约 currentScale > 1.93 的位置
      const targetScrollY = window.innerHeight * 1.3; // 根据页面高度，计算目标位置，确保 currentScale > 1.93
      const start = window.scrollY;
      const distance = targetScrollY - start;
      const duration = 1000; // 滚动持续时间，单位为毫秒
      const startTime = performance.now();

      const scrollStep = (currentTime) => {
        const timeElapsed = currentTime - startTime;
        const progress = Math.min(timeElapsed / duration, 1);
        const easeProgress = progress < 0.5
            ? 2 * progress * progress
            : 1 - Math.pow(-2 * progress + 2, 2) / 2;
        window.scrollTo(0, start + distance * easeProgress);
        if (timeElapsed < duration) {
          requestAnimationFrame(scrollStep);
        }
      };
      requestAnimationFrame(scrollStep);
    },
    handleBeforeUnload() {
      // 页面刷新或关闭时，将滚动条滚动到顶部
      window.scrollTo(0, 0);
    },
    onImageLoad(){
      this.imgLoadState = true
    },
    initGsapAnimation() {
      gsap.registerPlugin(ScrollTrigger);

      gsap
          .timeline({
            scrollTrigger: {
              trigger: ".wrapper",
              start: "top top",
              end: "+=150%",
              pin: true,
              scrub: true,
              markers: false,
              onUpdate: (self) => this.checkCircleBounds(self),
            },
          })
          .to(".bg-mian", {
            scale: 2,
            z: 350,
            transformOrigin: "center 10%",
            ease: "power1.inOut",
          })
          .to(
              ".section.hero",
              {
                scale: 1.0,
                transformOrigin: "center center",
                ease: "power1.inOut",
              },
              "<"
          );
    },
    checkCircleBounds(self) {
      const currentScale = gsap.getProperty(".bg-mian", "scale");

      // 判断 scale 值在 1 到 1.14147 之间，逐渐隐藏 image-welcome
      if (currentScale >= 1 && currentScale <= 1.14147) {
        const opacity = 1 - (currentScale - 1) / (1.14147 - 1);
        gsap.to(".image-welcome", { opacity: opacity, display: 'block' });
        this.scrollText = '往下滑动'
      }
      // 判断 scale 值在 1.9355 到 1.9999 之间，显示 image-welcome
      else if (currentScale >= 1.9355 && currentScale <= 1.9999) {
        gsap.to(".image-welcome", { opacity: 1, display: 'block' });
        this.scrollText = '继续滑动'
      }
      // 判断 scale 值大于 1.14147 并且小于 1.9355，隐藏 image-welcome
      else if (currentScale > 1.14147 && currentScale < 1.9355) {
        gsap.to(".image-welcome", { opacity: 0, display: 'none' });
      }
      // 判断 scale 值大于 1.9999 时，隐藏 image-welcome
      else if (currentScale > 1.9999) {
        gsap.to(".image-welcome", { opacity: 0, display: 'none' });
      }
      // 当 scale 小于 1 时，显示 image-welcome 层
      else if (currentScale < 1) {
        gsap.to(".image-welcome", { opacity: 1, display: 'block' });
        this.scrollText = '往下滑动'
      }

      this.previousScale = currentScale;
    }
  }
};
</script>

<style scoped>
@import "./root.css";
@import "./index.css";
</style>

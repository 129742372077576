<template>
<div>

  <el-row>
    <el-col :span="24">
      <h3><span class="introduce-character-text-title">历史版本更新记录</span></h3>
    </el-col>
  </el-row>

  <el-row>
    <el-col :span="24">
      <h3><span class="introduce-character-text">如果遇到任何使用上的问题，可联系作者，或者你的版本升级到最新版本
        可修复历史遗留问题
      </span></h3>
    </el-col>
  </el-row>


  <el-row style="margin-top: 20px;margin-left: 10px;">
    <el-col :span="24">
      <el-timeline>
        <el-timeline-item v-for="(item,index) in versionList" :timestamp="item.time" placement="top">

            <h4 class="introduce-character-text-title" style="margin-bottom: 10px;">{{item.title}}</h4>

            <span class="introduce-character-text" >{{item.content}}</span>

            <h6 class="introduce-character-text" style="margin-top: 10px;">{{item.tag}}</h6>
        </el-timeline-item>
      </el-timeline>


    </el-col>
  </el-row>

</div>


</template>

<script>
export default {
  name: "index",
  data(){
    return{
      versionList:[
        {
          time:'2024/12/03 11:26',
          title:'版本V1.5.5',
          content:'优化关闭的时候，需要重置默认值 ',
          tag:'susantyp 2024/12/03 11:26'
        },
        {
          time:'2024/11/25 15:52',
          title:'版本V1.5.4',
          content:'mimeType 兼容性问题',
          tag:'susantyp 2024/11/25 15:52'
        },
        {
          time:'2024/11/25 10:33',
          title:'版本V1.5.3',
          content:'增加人脸特征值的返回 susantyp 2024/11/25 10:33',
          tag:'susantyp 2024/11/25 10:33'
        },
        {
          time:'2024/11/22 14:15',
          title:'版本V1.5.2',
          content:'优化人脸添加相关 susantyp 2024/11/22 14:15',
          tag:'susantyp 2024/11/22 14:15'
        },
        {
          time:'2024/11/21 23:35',
          title:'版本V1.3.8',
          content:'优化，重新init的时候创建dom失败的问题 susantyp 2024/11/21 23:35',
          tag:'susantyp 2024/11/21 23:35'
        },
        {
          time:'2024/11/21 17:35',
          title:'版本V1.3.7',
          content:'优化登录流程创建 susantyp 2024/11/21 17:35',
          tag:'susantyp 2024/11/21 17:35'
        },
        {
          time:'2024/10/23 17:42',
          title:'版本V1.3.6',
          content:'人脸添加增加转向提示,更改打卡，添加的默认值 ,更改眨眨眼的默认值 ,appearance 阻止默认创建',
          tag:'susantyp 2024/10/23 17:42'
        }
      ]
    }
  }
}
</script>

<style scoped>

</style>

<template>
  <div class="doc-main">
      <div class="doc-head-title" ref="headTitle" >
        <el-row>
          <el-col :span="5">
            <img style="width: 75px;height: 75px;margin-top: 18px;" src="~@/assets/logo.png" alt="">
          </el-col>
          <el-col  :span="19">
            <div class="doc-head-title-body">

              <div class="doc-head-title-body-text">
                <el-dropdown trigger="click">
                    <span class="el-dropdown-link" style="cursor: pointer;">
                      V1.5.5<i class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item>V1.5.5</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>

              <div class="doc-head-title-body-text">
                <div class="container-togglesw">
                  <label class="switch">
                    <input class="togglesw" type="checkbox" v-model="blackOrWhiteState" @change="blackOrWhiteChange">
                    <div class="indicator left"></div>
                    <div class="indicator right"></div>
                    <div class="button"></div>
                  </label>
                </div>
              </div>



              <div class="doc-head-title-body-text doc-head-title-show" @click="$router.push('/faq')" style="cursor: pointer;">
                问题
              </div>

              <div class="doc-head-title-body-text doc-head-title-show" @click="$router.push('/attention')" style="margin-left: 25px;cursor: pointer;">
                使用声明
                <span v-if="shelterState" class="unread-point">·</span>
              </div>

              <div class="doc-head-title-body-text doc-head-title-show" @click="$router.push('/feedback')" style="margin-left: 25px;cursor: pointer;">
                反馈中心
              </div>

              <div class="doc-head-title-body-text doc-head-title-show" @click="openSource('gitee')" >
                <img src="~@/assets/open/gitee.png" style="width: 25px;height: 25px;cursor: pointer;margin-right: -50px;margin-bottom: -7.3px;border-radius: 50%;background-color: #fff;">
              </div>

              <div class="doc-head-title-body-text doc-head-title-show" @click="openSource('github')" >
                <img src="~@/assets/open/github.png" style="width: 28px;height: 28px;cursor: pointer;margin-right: -65px;margin-bottom: -9px;border-radius: 50%;background-color: #fff;">
              </div>

<!--              <div class="doc-head-title-body-input">-->
<!--                <el-autocomplete-->
<!--                    size="small"-->
<!--                    v-model="searchText"-->
<!--                    :fetch-suggestions="querySearch"-->
<!--                    @select="autocomplete"-->
<!--                placeholder="搜索文档"-->
<!--                >-->
<!--                <template slot-scope="{ item }">-->
<!--                  <div class="title-search-value">{{ item.page }}</div> &lt;!&ndash; 标题 &ndash;&gt;-->
<!--                  <span class="title-search-value2">{{ item.value }}</span> &lt;!&ndash; 副标题 &ndash;&gt;-->
<!--                  <span class="title-search-name" v-html="highlightMatch(item.content, searchText)"></span> &lt;!&ndash; 高亮匹配内容 &ndash;&gt;-->
<!--                </template>-->
<!--                </el-autocomplete>-->

<!--              </div>-->

            </div>
          </el-col>
        </el-row>
      </div>

      <div class="doc-main-body" >
        <el-row >
          <el-col :span="24">&nbsp;</el-col>
        </el-row>

        <el-row >
          <el-col :span="4" >
            <el-row>
              <el-col :span="24" v-for="(item,index) in sidebarList" :key="index+'sidebar'">
                <div class="parentName noselect">{{item.parentName}}</div>
                <el-row>
                  <el-col :span="20" :offset="1" v-for="it in item.child" class="child-sidebar" :key="it.name"
                   @click.native="toPage(it)"
                  :style="{color:it.selected ? '#84e0f9' : 'var(--current-page-font-color)',
                  backgroundColor:it.selected ? 'var(--current-page--sidebar-background-color)' : (blackOrWhiteState ? '#fff' : '#1b1b1d' )}"
                    >
                    <span class="child-sidebar-text noselect">
                      &nbsp;&nbsp;
                      {{it.name}}</span>
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
          </el-col>

          <el-col
              :offset="1"
              :span="15"
              :style="{height: (windowHeight - 110 - 20) + 'px' }"
              class="custom-scrollbar"
          ref="scrollContainer"
          style="overflow-y: scroll;">
          <router-view />
          <cut @cutChange="cutChange" class="doc-head-title-show" :key="'cut-'+cutIndex" />
          </el-col>


          <el-col  style="margin-left: 30px;" :span="3" class="doc-head-title-show">
            <div class="secondary-main"  v-if="titles && titles.length > 0">
              <div class="secondary-item" v-for="title in titles" @click="scrollToText(title)">{{title.textContent}}</div>
            </div>
            <advertising/>
          </el-col>

        </el-row>
      </div>
  </div>
</template>

<script>
const sidebar = require('../V1.0.0/sidebar')
import cut from "@/views/components/cut";
import advertising from "@/views/components/advertising";
export default {
  name: "index",
  components:{
      cut,advertising
  },
  data(){
    return {
      sidebarList:[],
      blackOrWhiteState:true,
      isFixed:false,
      windowHeight: 0,
      cutIndex:0,
      secondarySidebarList:[],
      currentSection:'',
      titles:null,
      scrollContainer:null,
      currentAnchor:'',
      searchText: "",
      fuse: null,
      shelterState:true,
      cache_num:0,
      city:''
    }
  },
  mounted() {
    this.setCityBackground()
    this.windowHeight = window.innerHeight;
    window.addEventListener('resize', this.updateWindowHeight);
    this.getTitles()
  },
  beforeDestroy() {
    // 组件销毁时移除监听器
    window.removeEventListener('resize', this.updateWindowHeight);
  },
  created() {
    this.sidebarList = sidebar.list
    this.selectedDefaultPage()
    this.shelterState = localStorage.getItem('shelterState') == null ? true : JSON.parse(localStorage.getItem('shelterState'))
    this.blackOrWhiteState = localStorage.getItem('blackOrWhiteState') == null ? true : JSON.parse(localStorage.getItem('blackOrWhiteState'))
    this.cache_num = localStorage.getItem('cache_num') == null ? 0 : parseInt(localStorage.getItem('cache_num'))
    if (this.cache_num === 0){
      this.$faceEffet.cache();
      this.cache_num += 1;
      localStorage.setItem('cache_num',this.cache_num)
    }
    this.$nextTick(()=>{
      this.updateColor()
    })
  },
  methods:{
    async setCityBackground() {
      try {
        // 调用 IP 定位 API 获取地理位置信息
        const response = await fetch('https://myip.ipip.net/json');
        const data = await response.json();
        if (data && data.data && data.data.location){
          this.city = data.data.location[2]
          this.updateBackground(this.city)
        }else {
          this.updateBackground('上海')
        }
      } catch (error) {
        this.updateBackground('上海')
      }
    },
    updateBackground(city) {
      let imagePath = '';
      switch (city) {
        case '北京':
          imagePath = require('@/assets/city/beijing.jpg');
          break;
        case '上海':
          imagePath = require('@/assets/city/shanghai.webp');
          break;
        case '深圳':
          imagePath = require('@/assets/city/shenzhen.webp');
          break;
        case '广州':
          imagePath = require('@/assets/city/guangzhou.jpg');
          break;
        default:
          imagePath = require('@/assets/city/shanghai.webp');
          break;
      }
      document.documentElement.style.setProperty(
          '--current-main-bg',
          `url(${imagePath})`
      );
    },
    openSource(val){
      if ('gitee' === val){
        window.open("https://gitee.com/susantyp/effet","_blank")
      }else {
        window.open("https://github.com/typsusan/effet","_blank")
      }
    },
    scrollToText(targetElement){
      if (targetElement) {
        targetElement.scrollIntoView({
          behavior: 'smooth', // 平滑滚动
          block: 'start', // 滚动到元素的顶部
        });
      }
    },
    getTitles(bol = true){
      this.$nextTick(()=>{
        this.titles = document.querySelectorAll('.introduce-character-text-title');
        this.scrollContainer = this.$refs.scrollContainer.$el || this.$refs.scrollContainer;
        if (bol){
          this.scrollContainer.scrollTo({
            top: 0,
            behavior: 'smooth' // 平滑滚动效果
          });
        }
      })
    },
    cutChange(path){
      this.deselectAll();
      this.selectedPath(path);
      this.getTitles()
    },
    updateWindowHeight() {
      this.windowHeight = window.innerHeight;
    },
    selectedDefaultPage(){
      this.deselectAll()
      let currentPath =  localStorage.getItem('path')
      if (currentPath){
        this.sidebarList.forEach(parent => {
          parent.child.forEach(child => {
            if (child.path === currentPath) {
                child.selected = true;
            }
          });
        });
        if (this.$route.path !== currentPath) {
          this.$router.push(currentPath)
        }
      }else {
        this.sidebarList[0].child[0].selected = true
      }
    },
    blackOrWhiteChange(e){
      localStorage.setItem('blackOrWhiteState',this.blackOrWhiteState)
      this.updateColor()
    },
    updateColor(){
      const root = document.documentElement;
      if (root){
        if (this.blackOrWhiteState){
          root.style.setProperty('--current-page-background-color', '#fff');
          root.style.setProperty('--current-page-font-color', '#1c1e21');
          root.style.setProperty('--current-page--sidebar-background-color', 'rgba(242, 242, 242,1)');
          root.style.setProperty('--current-page-line-color', '#dbdee5');
          root.style.setProperty('--current-page-el-dropdown-menu-bg', 'rgba(201, 201, 202,0.3)');
          root.style.setProperty('--current-page-code-background-color', '#fafafa');
        }else {
          root.style.setProperty('--current-page-background-color', '#1b1b1d');
          root.style.setProperty('--current-page-font-color', '#e3e3e3');
          root.style.setProperty('--current-page--sidebar-background-color', '#272729');
          root.style.setProperty('--current-page-line-color', '#606770');
          root.style.setProperty('--current-page-el-dropdown-menu-bg', '#606266');
          root.style.setProperty('--current-page-code-background-color', '#282c34');
        }
      }
    },
    toPage(obj){
        if (this.$route.path === obj.path) {
          return;
        }
        this.deselectAll();
        obj.selected = true
        this.$router.push(obj.path)
        localStorage.setItem('path',obj.path)
        this.cutIndex += 1
        this.getTitles()
    },
    deselectAll() {
      this.sidebarList.forEach(parent => {
        parent.child.forEach(child => {
          child.selected = false;
        });
      });
    },
    selectedPath(path){
      this.sidebarList.forEach(parent => {
        parent.child.forEach(child => {
          if (child.path === path) {
              child.selected = true;
              localStorage.setItem('path',path)
          }
        });
      });
    }
  }
};
</script>

<style>
@import './index.css';
</style>

<template>
<div>

  <el-row>
    <el-col :span="24">
      <h3><span class="introduce-character-text-title">使用声明<el-divider direction="vertical"></el-divider>免责声明</span></h3>
    </el-col>
  </el-row>

  <div class="effet-panel">
    ❗ 注意，Effet是一款人脸前端样式框架，不具备互联网接入、网络数据存储、通讯传输以及窃取用户隐私，
    请您仔细阅读并透彻理解本声明。您可以选择不使用 Effet ，若您一旦使用 Effet ，您的使用行为即被视为对本声明全部内容的认可和接受。
  </div>

  <el-row style="margin-top: 20px">
    <el-col :span="24" >
      <ul class="introduce-character">
        <li><b>特别声明：不保存用户人脸照片</b></li>
        <el-row style="margin-top: 15px;margin-bottom: 15px;">
          <el-col :span="24">
            <span class="introduce-character-text">
              Effet.js 是一个轻量级的纯前端人脸动画框架，旨在为开发者提供生动的面部动态效果展示。该框架不会也不具备保存用户人脸照片或图像的功能。Effet.js 仅用于人脸特征的实时识别与动画，不涉及任何与用户隐私相关的数据存储或处理功能。
            </span>
          </el-col>
        </el-row>
        <li><b>引入 FaceMesh.js 插件</b></li>
        <el-row style="margin-top: 15px;margin-bottom: 15px;">
          <el-col :span="24">
            <span class="introduce-character-text">
              Effet.js 框架内集成了开源项目 FaceMesh.js 的相关插件文件，包括但不限于以下内容：<br/><br/>

              <el-tag type="warning">face_mesh.binarypb</el-tag><br/>
              <el-tag type="warning">face_mesh_solution_packed_assets.data</el-tag><br/>
              <el-tag type="warning">face_mesh_solution_packed_assets_loader.js</el-tag><br/>
              <el-tag type="warning">face_mesh_solution_simd_wasm_bin.js</el-tag><br/>
              <el-tag type="warning">face_mesh_solution_simd_wasm_bin.wasm</el-tag><br/><br/>
              这些插件的引入是为了实现面部特征的识别和动画效果的优化，框架不涉及对插件本身功能的修改或扩展。
            </span>
          </el-col>
        </el-row>
        <li><b>文件缓存机制</b></li>
        <el-row style="margin-top: 15px;margin-bottom: 15px;">
          <el-col :span="24">
            <span class="introduce-character-text">
              Effet.js 框架会首先通过 CDN（内容分发网络）下载上述插件文件，并将它们缓存在用户浏览器的 IndexedDB 中。这种缓存机制旨在提高页面加载速度，减少带宽消耗。IndexedDB 是浏览器提供的本地存储解决方案，用户可通过浏览器自行管理或删除缓存的文件。
            </span>
          </el-col>
        </el-row>
        <li><b>插件文件用途说明</b></li>
        <el-row style="margin-top: 15px;margin-bottom: 15px;">
          <el-col :span="24">
            <span class="introduce-character-text">
              <el-tag type="warning">face_mesh.binarypb</el-tag> 和
              <el-tag type="warning">face_mesh_solution_packed_assets.data</el-tag>：用于存储面部识别和特征点数据。<br/>
<el-tag type="warning">face_mesh_solution_packed_assets_loader.js</el-tag>：用于加载必要的资源文件，确保面部识别功能的正常运行。<br/>
<el-tag type="warning">face_mesh_solution_simd_wasm_bin.js</el-tag> 和 <el-tag type="warning">face_mesh_solution_simd_wasm_bin.wasm</el-tag>：为面部识别功能提供高性能的 WebAssembly 支持，确保识别过程高效流畅。
这些文件的主要目的是为了实现面部特征的识别和追踪动画，Effet.js 框架不会使用它们进行其他用途。
            </span>
          </el-col>
        </el-row>
        <li><b>责任声明</b></li>
        <el-row style="margin-top: 15px;margin-bottom: 15px;">
          <el-col :span="24">
            <span class="introduce-character-text">
Effet.js 框架仅为前端的 UI 动画组件，不具备任何后台数据存储或传输功能，也不负责收集、存储或处理用户的个人信息。您承诺在遵守法律法规的前提下合理、合法地使用 Effet.js，不利用本框架进行任何违法或侵权行为。
<br/><br/>
任何因使用 Effet.js 框架产生的意外、损失或法律责任（包括但不限于直接、间接、附带或衍生的损失），本团队(Effet开发团队)不承担任何责任。您应对使用 Effet.js 可能产生的所有风险和后果承担完全责任。本框架的开发团队对任何由于不当使用 Effet.js 导致的侵权或违法行为概不负责。
            </span>
          </el-col>
        </el-row>
        <li><b>知识产权声明</b></li>
        <el-row style="margin-top: 15px;margin-bottom: 15px;">
          <el-col :span="24">
            <span class="introduce-character-text">
Effet.js 框架的所有代码及相关内容均受相关法律法规的保护。未经本团队(Effet开发团队)明确书面授权，任何个人或单位不得擅自对 Effet.js 框架本身申请知识产权保护，或用于任何违反法律法规的用途。
            </span>
          </el-col>
        </el-row>
        <li><b>声明条款变更权</b></li>
        <el-row style="margin-top: 15px;margin-bottom: 15px;">
          <el-col :span="24">
            <span class="introduce-character-text">
本团队(Effet开发团队)保留随时对本声明内容进行修改的权利。修改后的声明内容将通过消息推送或网页公告的形式公布，并自公布时起生效。若您在声明内容更新后继续使用 Effet.js，表示您已充分阅读、理解并接受修改后的声明条款。
            </span>
          </el-col>
        </el-row>
      </ul>

    </el-col>
  </el-row>


  <el-row style="margin-left: 20px;">
    <el-col :span="24">
      <h3><span class="introduce-character-text">发布日期：2024年9月11号</span></h3>
    </el-col>
    <el-col :span="24">
      <h3><span class="introduce-character-text">生效日期：2024年9月11号</span></h3>
    </el-col>
  </el-row>

</div>
</template>

<script>
export default {
  name: "index"
}
</script>

<style scoped>

</style>

<template>
  <div>

    <el-row>
      <el-col :span="24">
        <h3><span class="introduce-character-text-title">重写函数简介</span></h3>
      </el-col>
    </el-row>

    <el-row style="margin-top: 10px">
      <el-col :span="24">
        <span class="introduce-character-text">
          重写函数是我们实现自定义动作的核心。对于熟悉
          <el-tag size="small" type="info" color="#84e0f9">Effet.js</el-tag>
          的同学，应该知道其主要功能包括人脸登录、打卡、睡眠检测及人脸添加。其中，<el-tag type="info" size="small" color="#84e0f9">action</el-tag> 动作函数是核心实现。
        </span>
      </el-col>
    </el-row>

    <el-row style="margin-top: 20px;">
      <el-col :span="24">
        <span class="introduce-character-text">
          下面是一个人脸登录的简单示例，支持眨眼、张嘴、摇头等动作。如果你想添加、删除或仅保留特定动作，完全可以通过重写动作函数来实现。
        </span>
      </el-col>
    </el-row>

    <el-row>
      <el-col :span="24">
        <div v-highlight>
            <pre>
              <code class="vue">{{defaultCode}}</code>
            </pre>
        </div>
      </el-col>
    </el-row>

    <el-row style="margin-top: 20px;">
      <el-col :span="24">
        <span class="introduce-character-text">
          通过重写框架内部的
          <el-tag type="info" size="small" color="#84e0f9">action</el-tag>
          函数，你可以完成自定义动作。
        </span>
      </el-col>
    </el-row>

    <el-row>
      <el-col :span="24">
        <div v-highlight>
            <pre>
              <code class="vue">{{rewriteCode}}</code>
            </pre>
        </div>
      </el-col>
    </el-row>

  </div>
</template>

<script>
import rewriteCode from "@/views/V1.0.0/views/rewrite/rewriteCode";
export default {
  name: "index",
  data(){
    return{
      defaultCode:rewriteCode.defaultCode,
      rewriteCode:rewriteCode.rewriteCode
    }
  }
}
</script>

<style scoped>

</style>

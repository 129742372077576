<template>
<div>
  <el-row>
    <el-col :span="24">
      <h3><span class="introduce-character-text-title">Effet.js 介绍</span></h3>
    </el-col>
  </el-row>

  <el-row style="margin-top: 20px">
    <el-col :span="24">
      <el-tag size="small" type="info" color="#84e0f9">Effet.js</el-tag>
      <span class="introduce-character-text">
      是一个轻量级的人脸样式框架，专注于为网页带来生动的面部动画和动态效果。通过简单易用的 API，
      开发者不仅可以实现眨眼、张嘴、摇头等基础表情，还可以应用于多种场景，如人脸打卡、人脸登录、睡眠监测等。同时，</span>
      <el-tag size="small" type="info" color="#84e0f9">Effet.js</el-tag>
      <span class="introduce-character-text">
        对外提供 API，用户可根据需求灵活定制，实现更多的动作或功能，使得前端应用更加互动、智能且个性化。</span>
    </el-col>
  </el-row>



  <el-row style="margin-top: 20px">
    <el-col :span="24">
      <h3> <span class="introduce-character-text-title">版本迭代</span></h3>
    </el-col>
  </el-row>


  <el-row style="margin-top: 20px;margin-left: 10px;">
    <el-col :span="24">
        <el-timeline :reverse="false">
          <el-timeline-item
              v-for="(activity, index) in activities"
              :key="index"
              :color="activity.color"
              :timestamp="activity.timestamp">
            <span class="introduce-character-text">{{activity.content}}</span>
          </el-timeline-item>
        </el-timeline>
    </el-col>
  </el-row>

  <el-row style="margin-top: 20px">
    <el-col :span="24">
      <h3><span class="introduce-character-text-title">名字由来</span></h3>
    </el-col>
  </el-row>


  <el-row style="margin-top: 20px">
    <el-col :span="24" >

      <el-row style="margin-bottom: 20px">
        <el-col :span="24">
          <el-tag size="small" type="info" color="#84e0f9">“Effet”</el-tag>
          <span class="introduce-character-text">是法语单词，意思是“效果”或“影响”。它通常用于描述某种动作、
          现象或事件所带来的结果或后果。类似于英语中的</span>
          <el-tag size="small" type="info" color="#84e0f9">“effect”</el-tag>。
        </el-col>
      </el-row>

      <el-row style="margin-bottom: 20px">
        <el-col :span="24">
          <span class="introduce-character-text">例如：</span>
        </el-col>
      </el-row>

      <ul class="introduce-character">
        <li><b>L'effet de la lumière</b> – 光的效果</li>
        <li><b>Avoir un effet positif </b> – 有积极的影响</li>
      </ul>

      <el-row style="margin-bottom: 20px">
        <el-col :span="24">
          <span class="introduce-character-text">
            如果你是在不同的语言或领域中看到这个词，可能会有稍微不同的解释，但在法语中的意思主要是“效果”或“影响”。
          </span>
        </el-col>
      </el-row>
    </el-col>
  </el-row>

  <el-row style="margin-top: 20px">
    <el-col :span="24">
      <h3><span class="introduce-character-text-title">读音</span></h3>
    </el-col>
  </el-row>

  <el-row style="margin-top: 20px">
    <el-col :span="24" >
      <ul class="introduce-character">
        <li><b>E</b> 发音类似于汉语的“艾” (短促)</li>
        <li><b>F</b> 发音和汉语拼音的 “f” 相同</li>
        <li><b>fet</b> 发音类似于汉语的“费”，但尾音较轻</li>
      </ul>

      <el-row style="margin-bottom: 20px">
        <el-col :span="24">
          <span class="introduce-character-text">完整发音像 “艾费”，音调平稳，不太重。</span>
        </el-col>
      </el-row>
    </el-col>
  </el-row>

  <el-row style="margin-top: 20px">
    <el-col :span="24">
      <h3><span class="introduce-character-text-title">特性</span></h3>
    </el-col>
  </el-row>

  <el-row style="margin-top: 20px">
    <el-col :span="24" >
      <ul class="introduce-character">
        <li>人脸登录：通过识别面部表情来完成打卡任务，减少传统打卡方式的繁琐。</li>
        <li>人脸打卡：提供基于人脸的认证功能，增强安全性和用户体验。</li>
        <li>睡眠检测：通过面部动态追踪来监控用户的睡眠状态，进一步结合应用场景。</li>
      </ul>

     <el-row style="margin-bottom: 20px">
       <el-col :span="24">
         <el-tag size="small" type="info" color="#84e0f9">Effet.js</el-tag>
         <span class="introduce-character-text">
         对外开放 API，使得开发者可以根据具体需求灵活定制表情或动作，提供更多个性化的功能。
         这对于需要提高用户交互性和智能化的前端应用来说，是非常有用的工具，这个框架的特点在于：
           </span>
       </el-col>
     </el-row>

      <ul class="introduce-character">
        <li>轻量级，减少了对页面性能的影响</li>
        <li>简单易用的 API，降低开发难度。</li>
        <li>可定制的动画效果，增强了场景适用性。</li>
      </ul>

    </el-col>
  </el-row>


  <el-row style="margin-top: 20px">
    <el-col :span="24">
      <h3><span class="introduce-character-text-title">特别感谢</span></h3>
    </el-col>
  </el-row>

  <el-row style="margin-top: 20px">
    <el-col :span="24" >
      <el-row >
        <el-col :span="24">
          <b><span class="introduce-character-text">感谢 FaceMesh.js 开源项目</span></b>
        </el-col>
      </el-row>

      <el-row style="margin-bottom: 20px">
        <el-col :span="24">
          <span class="introduce-character-text">感谢 FaceMesh.js 的开源贡献，本框架正是基于该框架进行了二次开发。FaceMesh.js
            强大的功能和稳定的基础，为我们实现高效的人脸识别和动画效果提供了重要的支持。</span>
        </el-col>
      </el-row>

      <el-row >
        <el-col :span="24">
          <b><span class="introduce-character-text">感谢 Element UI 团队</span></b>
        </el-col>
      </el-row>

      <el-row >
        <el-col :span="24">
          <span class="introduce-character-text">感谢 Element UI 团队，本项目的文档构建基于 Element UI 进行，简洁美观的界面设计和易用的组件库使文档结构更加清晰、用户体验更加流畅。</span>
        </el-col>
      </el-row>

    </el-col>
  </el-row>


</div>
</template>

<script>
export default {
  name: "index",
  data(){
    return {
      activities: [
        {
          content: '单独抽离出来一款前端人脸样式框架，Effet.js上线构造完成',
          timestamp: '2024-09-11',
          color: '#84e0f9'
        },
        {
          content: 'Face-Server全新升级，单独人脸服务',
          timestamp: '2024-04-15'
        },
        {
          content: '革命性的更新Face-Server迎来',
          timestamp: '2024-03-21'
        },
        {
          content: '首个草稿版本Face-UI上线',
          timestamp: '2022-07-21'
        }
      ]
    }
  },
  mounted() {

  }
}
</script>



let parameterMain = [
    {
        name: 'el',
        type: 'String | HtmlElement',
        use: 'ALL',
        default: '',
        required: '是',
        desc: '主要face框架容器，可传入class,或者id，或者直接直接传一个元素',
        childTable:false,
        defState:false,
    },
    {
        name: 'blur',
        type: 'Number',
        use: '人脸登录',
        default: '8',
        required: '否',
        desc: '针对于 “人脸登录” 模式 登录成功后，镜头的模糊度',
        childTable:false,
        defState:false,
    },
    {
        name: 'face',
        type: 'Object',
        use: 'ALL',
        default: '',
        required: '否',
        desc: '当前对象是基于FaceMesh.js 的二次封装，默认值是effet.js 自带参数，可以传FaceMesh.js的参数体',
        childTable:true,
        childSkip:'face-parameters',
        defState:true,
        defSkip:'face-parameters-def'
    },
    {
        name: 'type',
        type: 'String',
        use: 'ALL',
        default: 'checkLogin',
        required: '否',
        desc: 'type 来进行不同人脸模式的切换，可选类型：addFace（人脸添加），checkLogin（人脸登录），checkSleep（睡眠检测），clockIn（人脸打卡）',
        childTable:false,
        defState:false,
    },
    {
        name: 'appearance',
        type: 'Boolean',
        use: '人脸登录，人脸添加',
        default: 'checkLogin',
        required: '否',
        desc: '当为false的时候，则不应用任何样式，睡眠检测，人脸打卡目前没有任何样式  ',
        childTable:false,
        defState:false,
    },
    {
        name: 'size',
        type: 'String',
        use: '人脸登录，人脸添加',
        default: 'mid',
        required: '否',
        desc: '可选值：max,mid,min  当前样式框的大小  ',
        childTable:false,
        defState:false,
    },
    {
        name: 'threshold',
        type: 'Object',
        use: '人脸登录',
        default: '{lips:0.05,eye:0.015,headShake:0.01}',
        required: '否',
        desc: 'lips：张张嘴的阈值，eye：眨眨眼的阈值，headShake：左右摇头的阈值',
        childTable:false,
        defState:false,
    },
    {
        name: 'before',
        type: 'function',
        use: 'ALL',
        default: '',
        required: '否',
        desc: '人脸框架执行前的前置函数，一但使用了before函数，则会覆盖框架自带的before函数',
        childTable:true,
        childSkip:'face-parameters-before',
        defState:false,
    },
    {
        name: 'action',
        type: 'function',
        use: 'ALL',
        default: '',
        required: '否',
        desc: '人脸动作函数，一但使用action函数，则会覆盖框架自带的action函数',
        childTable:true,
        childSkip:'face-parameters-action',
        defState:false,
    },
    {
        name: 'faceStyle',
        type: 'Object',
        use: 'ALL',
        default: 'null',
        required: '否',
        desc: '人脸样式网格样式，可以给人脸画一个网格线，单独给眼睛，鼻子，嘴巴轮廓单独上色',
        childTable:true,
        childSkip:'face-parameters-face-style',
        defState:false,
    },
    {
        name: 'punchDistance',
        type: 'Number',
        use: '人脸打卡',
        default: '25',
        required: '否',
        desc: '打卡距离，人脸正对摄像头的距离，默认25厘米',
        childTable:false,
        defState:false,
    },{
        name: 'punchSuccessColor',
        type: 'String',
        use: '人脸打卡',
        default: '#00d6e1',
        required: '否',
        desc: '打卡验证成功后的外框颜色',
        childTable:false,
        defState:false,
    },
    {
        name: 'sleepEarThreshold',
        type: 'Number',
        use: '睡眠检测',
        default: '0.2',
        required: '否',
        desc: '睡眠检测，当眼睛闭眼的阈值',
        childTable:false,
        defState:false,
    },{
        name: 'sleepTime',
        type: 'Number',
        use: '睡眠检测',
        default: '2',
        required: '否',
        desc: '睡眠检测，单位秒，当闭眼多久则判断是在睡眠',
        childTable:false,
        defState:false,
    },{
        name: 'sleepContinuousPush',
        type: 'Boolean',
        use: '睡眠检测',
        default: 'false',
        required: '否',
        desc: '当前检测到睡眠，是否持续推送消息，默认为false,只推送一次',
        childTable:false,
        defState:false,
    },
    {
        name: 'addFaceDistance',
        type: 'Number',
        use: '人脸添加',
        default: '25',
        required: '否',
        desc: '人脸添加距离，人脸正对摄像头的距离，默认25厘米',
        childTable:false,
        defState:false,
    },
    {
        name: 'callBack',
        type: 'function',
        use: 'ALL',
        default: '',
        required: '是',
        desc: '当前是一个回调函数，会打印整个人脸运行的回调消息，可在当前方法进行后端的调用',
        childTable:true,
        childSkip: 'face-parameters-callBack',
        defState:false,
    },
]

let face = [
    {
        name: 'maxNumFaces',
        type: 'Number',
        use: 'ALL',
        default: '1',
        required: '否',
        desc: '最大检测到的人脸数，如果选择的是 ”人脸登录“ 或者 ”人脸添加“ 模式，则当前值强制为 1',
        childTable:false,
        defState:false,
    },
    {
        name: 'refineLandmarks',
        type: 'Boolean',
        use: 'ALL',
        default: 'true',
        required: '否',
        desc: '是否精细化人脸特征点检测',
        childTable:false,
        defState:false,
    },
    {
        name: 'minDetectionConfidence',
        type: 'Number',
        use: 'ALL',
        default: '0.5',
        required: '否',
        desc: '最小检测置信度',
        childTable:false,
        defState:false,
    },
    {
        name: 'minTrackingConfidence',
        type: 'Number',
        use: 'ALL',
        default: '0.5',
        required: '否',
        desc: '最小跟踪置信度',
        childTable:false,
        defState:false,
    }
]


let callBack = [
    {
        name: 'videoElement',
        type: 'HTMLElement',
        use: '回调返回',
        default: '',
        required: '',
        desc: '当前人脸video标签视频的元素',
        childTable:false,
        defState:false,
    },
    {
        name: 'canvasElement',
        type: 'HTMLElement',
        use: '回调返回',
        default: '',
        required: '',
        desc: '当前人脸canvas标签的元素',
        childTable:false,
        defState:false,
    },
    {
        name: 'progressMessage',
        type: 'String',
        use: '回调返回',
        default: '',
        required: '',
        desc: '正在进行哪一步的回调消息',
        childTable:false,
        defState:false,
    },
    {
        name: 'parentElement',
        type: 'HTMLElement',
        use: '回调返回',
        default: '',
        required: '',
        desc: '当前el元素，整个主体，最外层',
        childTable:false,
        defState:false,
    },
    {
        name: 'step',
        type: 'Number',
        use: '回调返回',
        default: '',
        required: '',
        desc: '进行到了哪一步，返回数字类型',
        childTable:false,
        defState:false,
    },
    {
        name: 'base64Array',
        type: 'Array',
        use: '回调返回',
        default: '',
        required: '',
        desc: '当前人脸图片校验成功图片，可用于传输给后端接口',
        childTable:false,
        defState:false,
    },
    {
        name: 'video',
        type: 'String MP4 base64编码',
        use: '人脸登录',
        default: '',
        required: '',
        desc: '人脸拍摄视频，只有人脸登录模式才有，其他模式为NULL',
        childTable:false,
        defState:false,
    },
    {
        name: 'secretKey',
        type: 'String',
        use: '回调返回',
        default: '',
        required: '',
        desc: '唯一密钥值，同时当前图片的左上角和右下角也被注入了水印密钥值，可一并传入后台根据密钥对比来当前图片',
        childTable:false,
        defState:false,
    },
    {
        name: 'features',
        type: 'Array',
        use: '回调返回',
        default: '',
        required: '',
        desc: '人脸特征值，由468个人脸点位组成',
        childTable:false,
        defState:false,
    }
]

let beforeCode = `effet.init({
    el: 'myface',
    blur: 8,
    face: {
        maxNumFaces: 1,
        refineLandmarks: true,
        minDetectionConfidence: 0.5,
        minTrackingConfidence: 0.5
    },
    type:'checkLogin',
    threshold:{
        lips:0.05,
        eye:0.015,
        headShake:0.01,
    },
    /**
     * @param appData 当前框架内置对象所有属性值
     * @param currentObj 当前对象
     * @param callBackResult 回调函数
     * @param stopRecording 停止录制
     * @param startRecording 重新开始录制，可以不用调用，可以直接调用effet.restart方法
     */
    before:(appData,currentObj,callBackResult,stopRecording,startRecording)=>{
        console.log(appData)
        console.log(currentObj)
        // 调用回调函数
        callBackResult(currentObj, '请张张嘴巴');
        // 停止录制
       // stopRecording(currentObj)
    }
});`

let actionCode = `let result = 0;
effet.init({
        el: 'myface',
        blur: 8,
        face: {
            maxNumFaces: 1,
            refineLandmarks: true,
            minDetectionConfidence: 0.5,
            minTrackingConfidence: 0.5
        },
        type:'checkLogin',
        threshold:{
            lips:0.05,
            eye:0.015,
            headShake:0.01,
        },
        /**
         * @param appData 当前框架内置对象所有属性值
         * @param results 人脸关键点位
         * @param currentObj 当前对象
         * @param callBackResult 回调函数
         * @param stopRecording 停止录制
         * @param startRecording 重新开始录制，可以不用调用，可以直接调用effet.restart方法
         */
        action:(appData,results,currentObj,callBackResult,stopRecording,startRecording)=>{
                const landmarks = results.multiFaceLandmarks[0]; // 拿到第0张人脸，可循环
                // 获取面部关键点
                const upperLipBottom = landmarks[13];
                const lowerLipTop = landmarks[14];
                const leftEyeTop = landmarks[159];
                const leftEyeBottom = landmarks[145];
                const rightEyeTop = landmarks[386];
                const rightEyeBottom = landmarks[374];
                const noseTip = landmarks[1]; // 鼻尖的标记点
                result += noseTip.y;
                if (result >= 200){
                    stopRecording(currentObj)
                }
                console.log(result)
        }
    });`

let faceStyle = `effet.init({
    el: 'myface',
    blur: 8,
    face: {
        maxNumFaces: 1,
        refineLandmarks: true,
        minDetectionConfidence: 0.5,
        minTrackingConfidence: 0.5
    },
    type:'checkLogin',
    threshold:{
        lips:0.05,
        eye:0.015,
        headShake:0.01,
    },
    faceStyle: {
        faceColor: { // 整个人脸网格颜色
            color:'#21d86e', // 线条颜色
            line:1 // 线条粗度
        },
        rightEye:{ // 右眼颜色
            color:'#297ae9',
            line:2,
        },
        rightEyebrow:{ // 右眉毛
            color:'#297ae9',
            line:2,
        },
        rightIris:{ // 右眼珠
            color: 'red',
            line:1
        },
        leftEye:{ // 左眼颜色
            color: 'red',
            line:1
        },
        leftEyebrow:{ // 左眉毛
            color: 'blue',
            line:1
        },
        leftIris:{ // 左眼珠
            color: 'blue',
            line:1
        },
        oval:{ // 脸型最外层轮廓颜色
            color: 'blue',
            line:1
        },
        lips:{ // 嘴唇颜色
            color: 'red',
            line:1
        }
    }
});`

export default {
    parameterMain,face,beforeCode,actionCode,faceStyle,
    callBack
}
